<template>
  <div>
    <Progress v-if="loading" />
    <v-card tile v-if="!loading">
      <v-card-text class="text-center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="8" align="right">
              <v-btn color="primary" medium tile elevation="1" @click="add()">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="products"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
        >
          <template v-slot:item.price="{ item }">
            <span style="text-align:right">
              Rp. {{ item.price | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.base_price="{ item }">
            <span style="text-align:right">
              Rp. {{ item.base_price | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.begin_price="{ item }">
            <span style="text-align:right">
              Rp. {{ item.begin_price | numeral("0,0") }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="edit(item)" color="success">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>

    <formInput v-if="dialog_form" v-on:go="go" v-on:close="close" />
    <dialogDelete
      :dialog="dialog_delete"
      data="Product"
      v-on:close="close"
      v-on:deleteAction="deleteAction"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Progress from "../../../components/external/Progress";
import { mapActions, mapGetters } from "vuex";
import formInput from "./components/form";
import dialogDelete from "../../../components/external/DialogDelete";
import GlobalMixins from "../../mixin/index.js";
export default {
  mixins: [GlobalMixins],
  data() {
    return {
      user_edit: null,
      headers: [
        {
          text: "Product",
          align: "start",
          value: "name"
        },
        {
          text: "Code",
          value: "kode"
        },
        {
          text: "Price",
          value: "price"
        },
        {
          text: "Base Price",
          value: "base_price"
        },
        {
          text: "Begin Price",
          value: "begin_price"
        },
        {
          text: "SKU",
          value: "sku"
        },

        {
          text: "Min Stock",
          value: "stock_minimum"
        },
        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      products: "data/products"
    })
  },
  components: {
    Progress,
    formInput,
    dialogDelete
  },
  methods: {
    activerow: function(item, b, event) {
      console.log(event);
      this.$refs.menu.open(0, 0);
    },
    async deleteAction() {
      const data = new URLSearchParams();
      data.append("is_deleted", "Y");
      data.append("update_by", this.user.fullname);
      await this.axios
        .put("v1/product/update/" + this.data_delete.id, data)
        .then(async ress => {
          this.setSnakbar({
            status: true,
            pesan: "Success delete data",
            color_snakbar: "success"
          });
          await this.go("product", "products");
          this.close();
        })
        .catch(err => console.log(err.response));
    }
  },
  async created() {
    if (this.products.length) {
      this.loading = false;
    }
  },
  async mounted() {
    await this.go("product", "products");
  }
};
</script>
