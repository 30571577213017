<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Product
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                  ref="form"
                  v-model="valid"
                  :lazy-validation="lazy"
                  >
                  <v-row>
                     <v-col cols="12" md="4" class="text-center">
                        <v-img style="cursor:pointer" :src="imglinks" @click="$refs.foto_profile.click()"></v-img>
                        <span style="color:red">Image max 300Kb</span>
                      </v-col>
                    <v-col cols="12" md="8">
                       <v-row>
                         <v-col cols="12">
                           <v-text-field
                            v-model="name"
                            label="Product Name"
                            :rules="nameRules"
                            required
                          ></v-text-field>
                         </v-col>
                         <v-col cols="12">
                           <v-text-field
                            v-model="kode"
                            label="Product Code"
                            :rules="nameRules"
                            required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              v-model="category"
                              :items="categories"
                              chips
                              label="Category"
                              item-text="name"
                              item-value="id"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    v-bind="data.attrs"
                                    style="color:white"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    :color="color"
                                    @click:close="remove('category')"
                                  >
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                  </template>
                                  <template v-else>
                                    <v-list-item-content>
                                      <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </template>
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                          v-model="uom_id"
                          :items="uom"
                          chips
                          label="Uom"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('uom_id')"
                              >
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                          </v-col>
                         <v-col cols="12" md="4">
                           <v-text-field
                          v-model="price_format"
                          label="Price"
                          :rules="priceRules"
                          required
                          ></v-text-field>
                         </v-col>
                         <v-col cols="12" md="4">
                         <v-text-field
                          v-model="base_price_format"
                          label="Base Price"
                          :rules="priceRules"
                          required
                        ></v-text-field>
                         </v-col>
                         <v-col cols="12" md="4">
                        <v-text-field
                          v-model="begin_price_format"
                          label="Begin Price"
                          :rules="priceRules"
                          required
                        ></v-text-field>

                         </v-col>

                         <v-col cols="12">
                           <v-text-field
                          v-model="stock_minimum"
                          label="Stock Minimum"
                          :rules="priceRules"
                          required
                          ></v-text-field>
                          <v-text-field
                            v-model="sku"
                            label="SKU"
                            required
                          ></v-text-field>
                         </v-col>
                         <v-col cols="12" md="6">
                           <v-switch
                            v-model="stock"
                            inset
                            label="Stock"
                          ></v-switch>
                         </v-col>
                          <v-col cols="12" md="6">
                           <v-switch
                            v-model="sale"
                            inset
                            label="Sale"
                          ></v-switch>
                         </v-col>
                         <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                       </v-row>
                    </v-col>
                  </v-row>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">

               </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MixinGlobal from '../../../mixin/index'
export default {

  mixins:[MixinGlobal],
  methods: {
    send_data() {
      const data =new FormData()
      data.append('uom_id',this.uom_id)
      data.append('category_id',this.category)
      data.append('name',this.name)
      data.append('kode',this.kode)
      data.append('sku',this.sku)
      data.append('note',this.note)
      data.append('price',this.price)
      data.append('base_price',this.base_price)
      data.append('begin_price',this.begin_price)
      data.append('is_stock',this.stock == true ? 'Y' : 'N')
      data.append('is_sale',this.sale == true ? 'Y' : 'N')
      data.append('stock_minimum',this.stock_minimum)
      data.append('photos',this.foto)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)

      return data
    },
    async save () {
      this.loading = true
      const data =this.send_data()
      await this.axios.post('v1/product/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          this.go('product','products')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
       const data =this.send_data()
      await this.axios.put('v1/product/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          this.go('product','products')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.product = ''
      this.quantity = ''
      this.product_part = ''
      this.uom_id = ''
      this.category= ''
      this.name = ''
      this.kode = ''
      this.price = 0
      this.begin_price= 0
      this.base_price= 0
      this.sku = ''
      this.note = ''
      this.imgurl = 'img/no_img.png'

      this.stock_minimum = ''
      this.stock =  false
      this.sale =  false
    },
    remove(data) {
      this[data] = ''
    },

  },
  data: () => ({
    valid: true,
    lazy: false,
    loading:false,
    uom_id: '',
    category: '',
    name:'',
    kode:'',
    price:0,
    begin_price:0,
    base_price:0,
    sku:'',
    note:'',
    stock_minimum:0,
    stock:false,
    sale:false,
    nameRules: [
      v => !!v || 'Required field'
    ],
    priceRules: [
      v => !!v || 'Required field',
      v => /^[0-9,]+$/.test(v) || 'Format Price (1,000,xxx)',
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    ...mapGetters({
      uom: 'data/uom',
      categories: 'data/categories',
    }),
    backgroundColor () {
      return `background-color:${this.color} !important`
    },
    imglinks: function () {
      if (this.edit_data) {
        if (this.edit_data.image_link) {
          return process.env.VUE_APP_IMGLINK + 'product/' + this.edit_data.image_link
        } else {
          return this.imgurl
        }
      } else {
        return this.imgurl
      }
    },
    price_format:{
      get(){
          return this.formatAsCurrency(this.price, 0)
      },
      set(newValue){
          this.price= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    },
    begin_price_format:{
      get(){
          return this.formatAsCurrency(this.begin_price, 0)
      },
      set(newValue){
          this.begin_price= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    },
    base_price_format:{
      get(){
          return this.formatAsCurrency(this.base_price, 0)
      },
      set(newValue){
          this.base_price= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    }

  },
  async created () {
    if (this.edit_data) {
      this.product = this.edit_data.product_id
      this.quantity = this.edit_data.qty
      this.product_part = this.edit_data.part_product_id
      this.uom_id = this.edit_data.uom_id
      this.category= this.edit_data.category_id
      this.name = this.edit_data.name
      this.kode = this.edit_data.kode
      this.price = parseInt(this.edit_data.price)
      this.begin_price= parseInt(this.edit_data.begin_price)
      this.base_price= parseInt(this.edit_data.base_price)
      this.sku = this.edit_data.sku
      this.note = this.edit_data.note
      this.stock_minimum = this.edit_data.stock_minimum
      this.stock = this.edit_data.is_stock == 'Y' ? true : false
      this.sale = this.edit_data.is_sale == 'Y' ? true : false
    }
    await this.get_data_master('uom','uom')
    await this.get_data_master('category','categories')
  }
}
</script>
